import axios from "axios";

const report = {
  actions: {   
    downloadgeneralreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadGeneralReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadmtgeneralreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadMtGeneralReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadmtscorecardreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadMtScoreCardReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadgtscorecardreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadGtScoreCardReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadpricepickupreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadPricePickupReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
    downloadpricepickupgeneralreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadPricePickupGeneralReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadsummaryreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadSummaryReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadmerchandisersummaryreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadMerchandiserSummaryReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadnationaldetailsreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadNationalDetailsReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadnationaldetailsmodernreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadNationalDetailsModernReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadnationaldetailsselfmerchandizing({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/NationalDetailsSelfMerchandizing?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadshareofshelfreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadShareofShelfReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadskusummaryreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadSkuSummaryReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadcompetitorpricecomparisonreport({ rootState }, {OutletId,KpiReferenceId}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadCompetitorPriceComparisonReport?OutletId=${OutletId}&KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadPerfectStoreReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadPerfectStoreReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadOsaPerfomanceReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadOsaPerfomanceReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadNpdPerfomanceReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadNpdPerfomanceReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadOutletVisitationPerfomanceReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadOutletVisitationPerfomanceReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetOverviewReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/GetOverviewReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetDashboardGraph() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Dashboard/GetDashboardGraph`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetDashboardVisitationGraph() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Dashboard/GetDashboardVisitationGraph`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetReportGenerated({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/GetReportGenerated?Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default report;
